<template>
  <div>
    <PageTitlebar :title="'Edit Advertiser Account - ' + advertiserAccountId">
      <template v-slot:top>
        <Link
          :to="{ name: 'AdvertiserAccountsOverview' }"
          label="Back to Accounts"
          icon="sym_r_chevron_left"
          class="q-mb-sm"
        />
      </template>
      <!--<template v-slot:actions>
        <Link
          :to="{ name: 'AdvertiserAccountsOverview' }"
          label="Edit Inventory Sources"
        />
      </template>-->
    </PageTitlebar>

    <AdvertiserAccountForm
      type="edit"
      :advertiser-account-id="advertiserAccountId"
      :submitting="submitting"
      @submit="editAdvertiserAccount"
    />
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import AdvertiserAccountForm from "@/components/Advertiser/AdvertiserAccountForm";
import Link from "@/components/UI/Link";

export default {
  name: "AdvertiserAccountEditor",
  props: {
    advertiserAccountId: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    Link,
    AdvertiserAccountForm,
    PageTitlebar
  },
  data() {
    return {
      submitting: false
    };
  },
  watch: {},
  mounted() {},
  methods: {
    editAdvertiserAccount(formData) {
      this.submitting = true;

      this.$store
        .dispatch("advertiserAccounts/POST_REQUEST", {
          endpoint: "update",
          params: {
            id: this.advertiserAccountId,
            name: formData.name,
            product_type_id: formData.productTypeId,
            bid_method: formData.bidMethod,
            static_bid_value: formData.staticBidValue,
            daily_budget: formData.dailyBudgetValue,
            country_code: formData.countryCode
          }
        })
        .then(() => {
          this.triggerActionOutcomeAlert("save", true);
          this.backToTop();
        })
        .catch(e => {
          console.log(e);
          this.triggerActionOutcomeAlert("save", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
